<template>
    <b-container id="home" class="height-full d-flex align-items-center">
        <b-container class="text-center">
            <b-row>
                <b-col>
                    <b-img-lazy
                        style="width: 50%; margin-bottom: 50px"
                        alt="Arezzo&CO" fluid
                        :src=" getLogo() "
                    ></b-img-lazy>
                </b-col>
            </b-row>
            <b-row>
                <b-col v-on:click="openPlayStore()">
                    <b-img-lazy
                        alt="Play Store" fluid
                        class="cursor-pointer"
                        :src=" require('../../assets/img/play-store.png') "
                    ></b-img-lazy>
                </b-col>
                <b-col v-on:click="openAppleStore()">
                    <b-img-lazy
                        alt="Apple Store" fluid
                        class="cursor-pointer"
                        :src=" require('../../assets/img/apple-store.png') "
                    ></b-img-lazy>
                </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>
<script>
import {Utils} from "../../components/utils";

export default {
    name: 'download',
    mixins: [Utils],
    methods: {
        openPlayStore() {
            this.openUrl('https://play.google.com/store/apps/details?id=br.com.hub2154.zzvendas');
        },
        openAppleStore() {
            this.openUrl('https://apps.apple.com/br/app/zz-vendas/id1515150526');
        },
        openUrl(url) {
            window.open(url);
        }
    },
    mounted() {
        document.title = 'ZZVendas';
    }
}
</script>
